<!-- 
	This is the register page, it uses the dashboard layout in:
	"./layouts/Default.vue" .
 -->

<template>
	<div>

		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('images/bg-signup.jpg')">
			<div class="content">
				<h1 class="mb-5">注册</h1>
				<p class="text-lg">输入手机号注册</p>
			</div>
		</div>
		<!-- / Sign Up Image And Headings -->
		
		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{paddingTop: 0}">
			<p class="text-center my-25 font-semibold text-muted"></p>
			<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>
        <a-form-item class="mb-10" :colon="false" name="phoneNumber">
          <a-input v-decorator="['phoneNumber', {rules:registerRules.phoneNumber}]" placeholder="请输入手机号" />
        </a-form-item>
				<a-form-item>
					<a-button type="primary" block html-type="submit" class="login-form-button">
						注册
					</a-button>
				</a-form-item>
			</a-form>
			<p class="font-semibold text-muted text-center">已经有账户？ <router-link to="/login" class="font-bold text-dark">登录</router-link></p>
		</a-card>
		<!-- / Sign Up Form -->

	</div>
</template>

<script>

import {message} from "ant-design-vue";

export default {
  name: "sign-in",
  data() {
    return {
      codeUrl: "",
      registerForm: {
        phoneNumber: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: "",
      },
      registerRules: {
        phoneNumber: [
          { required: true, trigger: "blur", message: "请输入您的手机号" }
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" }
        ],
        code: [
          { required: true, trigger: "change", message: "请输入验证码" }
        ],
      },
      loading: false,
      redirect: undefined
    }
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: 'normal_login' });
  },
  methods: {
    // Handles input validation after submission.
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, value) => {
        if (!err) {
          message.error("请到网点或营业厅办理手机银行开通业务");
          // this.$store.dispatch("Register", value).then(() => {
          //   this.$router.push('/').catch(() => {
          //   });
          // });
        }
        else {
          console.log(err);
        }
      });
    },
  }
}

</script>

<style lang="scss">
</style>